import YouTubeVideo from "../common/video";

export default function CompanyMilestone() {
    const videoId = '9TVIMzEAlz4';
    return (
        <div className="flex flex-col items-center">
            <div className="my-16 mx-6 md:my-24">
                <h2 className="font-semibold md:text-4xl text-2xl text-center">디블록은 10배, 100배의 황금레버리지라는 <span className="date md:text-5xl text-3xl">"가치"</span>를 채굴합니다.</h2>
            </div>
            <div className="flex flex-col lg:flex-row justify-center items-center mb-12">
                <YouTubeVideo src={videoId} />
                <div className="lg:w-1/4 w-90 px-6 py-6 md:px-3 md:py-2 grey-background rounded-xl text-opacity-80 text-lg flex items-center justify-center">
                    <div>
                        <span className="font-semibold text-xl selected-text-color">
                            디블록의 역사는 2023년 8월 한 청년의 치기어린 열정으로 시작되었습니다.
                        </span>
                        <br></br>
                        <br></br>
                        <span className="leading-relaxed">
                            우연히 참여한 코인 채굴 설명회를 시작으로 수십권에 달하는 블록체인,
                            프로그래밍 전문서적(python, c, c++, java, unix)을 독파하였고 백여편에 가까운 블록체인 논문들을 통해 전문지식을 확립해나갔습니다.
                        </span>
                        <br></br>
                        <br></br>
                        <span className="leading-relaxed">
                            2023년 9월 cpu + network 채굴 기반 최초의 채굴기를 설치하였으며, 현재 성서, 전주, 평택, 화성, 경주 등 빠른속도로 확장하여 전국에 <span className="font-bold">500대 규모의 채굴장</span>을 운영하고 있습니다.
                        </span>
                    </div>
                </div>
            </div>
            <div></div>
        </div>
    )
}