import BasePage from './common/base';
import PageNotReady from './common/pageNotReady';


export default function Home() {
    return (
        <BasePage>
            {PageNotReady()}
        </BasePage>
    );
}
