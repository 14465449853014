import React from 'react';
import { useLocation } from 'react-router-dom';
import coverImg from '../../asset/img/채굴장1.jpg';

export default function MainPhoto() {
  const location = useLocation();

  const getTitle = () => {
    switch (location.pathname) {
      case '/about':
        return '회사/대표 소개';
      case '/place':
        return '채굴장 소개';
      case '/lecture':
        return '코인 백서';
      case '/package':
        return '채굴 패키지 소개'
      case '/package/profitable-coin':
        return '수익형 코인 채굴'
      case '/package/bitcoin-staking':
        return '비트코인 스테이킹'
      case '/package/ripple-staking':
        return '리플 스테이킹'
      default:
        return '에러';
    }
  };

  return (
    <div
      className="relative isolate px-6 pt-14 lg:px-8"
      style={{
        backgroundImage: `url(${coverImg})`,
        backgroundSize: 'cover',
        backgroundPosition: 'center',
      }}
    >
      <div className="mx-auto max-w-2xl py-32 sm:py-48 lg:py-56">
        <h1 className="text-center">
          <span className="text-2xl font-bold tracking-tight text-gray-50 sm:text-4xl bg-slate-900 bg-opacity-95 px-3 py-2 rounded-xl">
            {getTitle()}
          </span>
        </h1>
      </div>
    </div>
  );
}