import { useState } from 'react';
import logoImg from '../../asset/img/logo-only.jpg';
import {
  Dialog,
  DialogPanel,
  Disclosure,
  DisclosureButton,
  DisclosurePanel,
  Popover,
  PopoverButton,
  PopoverGroup,
  PopoverPanel,
} from '@headlessui/react'
import {
  ArrowPathIcon,
  Bars3Icon,
  ChartPieIcon,
  CursorArrowRaysIcon,
  FingerPrintIcon,
  SquaresPlusIcon,
  XMarkIcon,
} from '@heroicons/react/24/outline'
import { ChevronDownIcon, PhoneIcon, PlayCircleIcon } from '@heroicons/react/20/solid'
import BitcoinLogo from '../../asset/components/BitcoinLogo';
import RippleLogo from '../../asset/components/RippleLogo';
import MiningLogo from '../../asset/components/MiningLogo';
import IntroduceLogo from '../../asset/components/IntroduceLogo';

const navigation = [
  { name: '회사/대표 연혁', href: '/' },
  { name: '채굴장 소개', href: '/place' },
  { name: '코인 백서', href: '/lecture' },
]

const products = [
  { name: '패키지 소개', description: '칸 랩이 제공하고 있는 채굴 패키지에 대한 소개입니다', href: '/package', icon: IntroduceLogo },
  { name: '수익형 코인 채굴', description: '칸 랩이 선별한 코인 포트폴리오에 따라 상장된 채굴코인을 채굴하여 즉시 매도 합니다. 환급성이 뛰어난 수익형 채굴입니다.', href: '/package/profitable-coin', icon: MiningLogo },
  { name: '비트코인 스테이킹', description: '수익형 채굴 + 비트코인 매수형 패키지 입니다.', href: '/package/bitcoin-staking', icon: BitcoinLogo },
  { name: '리플 스테이킹', description: '수익형 채굴 + 리플 매수형 패키지 입니다.', href: '/package/ripple-staking', icon: RippleLogo },
]

export default function Example() {
  const [mobileMenuOpen, setMobileMenuOpen] = useState(false);
  const [activeMenuItem, setActiveMenuItem] = useState(null); // 활성화된 메뉴 항목 상태

  const handleMenuItemClick = (item) => {
    setActiveMenuItem(item.name); // 클릭된 항목을 활성화 상태로 설정
  };

  return (
    <header className="absolute inset-x-0 top-0 z-50 bg-white">
      <nav aria-label="Global" className="flex items-center justify-between p-3 lg:px-4">
        <div className="flex lg:flex-1">
          <a href="/" className="flex flex-row items-center">
            <span className="sr-only">디블록</span>
            <img
              alt=""
              src={logoImg}
              className="h-20 w-auto mr-1"
            />
            <div className='flex flex-col justify-center text-center'>
              <div className='mx-2 font-bold text-3xl'>D.BLOCK</div>
              <div className='mx-2 font-bold text-sm'>디블록</div>
            </div>
          </a>
        </div>
        <div className="flex lg:hidden">
          <button
            type="button"
            onClick={() => setMobileMenuOpen(true)}
            className="-m-2.5 inline-flex items-center justify-center rounded-md p-2.5 text-gray-700"
          >
            <span className="sr-only">Open main menu</span>
            <Bars3Icon aria-hidden="true" className="h-6 w-6" />
          </button>
        </div>
        <div className="hidden lg:flex lg:gap-x-24">
          {navigation.map((item) => (
            <a
              key={item.name}
              href={item.href}
              className={`text-m font-semibold leading-6 text-gray-900 
                ${activeMenuItem === item.name ? 'bg-gray-300 selected-text-color' : 'bg-white'} 
                hover:bg-gray-200 rounded-lg px-3 py-2`}
              onClick={() => handleMenuItemClick(item)}
            >
              {item.name}
            </a>
          ))}
          <PopoverGroup className="hidden lg:flex lg:gap-x-12 font-semibold leading-6 text-gray-900 hover:bg-gray-200 rounded-lg px-3 py-2">
            <Popover className="relative">
              <PopoverButton className="flex items-center gap-x-1 text-sm font-semibold leading-6 text-gray-900">
                채굴 패키지
                <ChevronDownIcon aria-hidden="true" className="h-5 w-5 flex-none text-gray-400" />
              </PopoverButton>

              <PopoverPanel
                transition
                className="absolute -left-8 top-full z-10 mt-3 w-screen max-w-md overflow-hidden rounded-3xl bg-white shadow-lg ring-1 ring-gray-900/5 transition data-[closed]:translate-y-1 data-[closed]:opacity-0 data-[enter]:duration-200 data-[leave]:duration-150 data-[enter]:ease-out data-[leave]:ease-in"
              >
                <div className="p-4">
                  {products.map((item) => (
                    <div
                      key={item.name}
                      className="group relative flex items-center gap-x-6 rounded-lg p-4 text-sm leading-6 hover:bg-gray-50"
                    >
                      <div className="flex h-11 w-11 flex-none items-center justify-center rounded-lg bg-gray-50 group-hover:bg-white">
                        <item.icon aria-hidden="true" className="h-6 w-6" />
                      </div>
                      <div className="flex-auto">
                        <a href={item.href} className="block font-semibold text-gray-900">
                          {item.name}
                          <span className="absolute inset-0" />
                        </a>
                        <p className="mt-1 text-gray-600">{item.description}</p>
                      </div>
                    </div>
                  ))}
                </div>
              </PopoverPanel>
            </Popover>
          </PopoverGroup>
        </div>
        <div className="hidden lg:flex lg:flex-1 lg:justify-end">
          <a href="#" className="text-sm font-semibold leading-6 text-gray-900">
            <span className='hidden'><span aria-hidden="true">&rarr;</span></span>
          </a>
        </div>
      </nav>
      <Dialog open={mobileMenuOpen} onClose={setMobileMenuOpen} className="lg:hidden">
        <div className="fixed inset-0 z-50" />
        <DialogPanel className="fixed inset-y-0 right-0 z-50 w-full overflow-y-auto bg-white px-6 py-6 sm:max-w-sm sm:ring-1 sm:ring-gray-900/10">
          <div className="flex items-center justify-between">
            <a href="#" className="-m-1.5 p-1.5">
              <span className="sr-only">칸 블록체인</span>
              <img
                alt=""
                src={logoImg}
                className="h-20 w-auto"
              />
            </a>
            <button
              type="button"
              onClick={() => setMobileMenuOpen(false)}
              className="-m-2.5 rounded-md p-2.5 text-gray-700"
            >
              <span className="sr-only">Close menu</span>
              <XMarkIcon aria-hidden="true" className="h-6 w-6" />
            </button>
          </div>
          <div className="mt-6 flow-root">
            <div className="-my-6 divide-y divide-gray-500/10">
              <div className="space-y-2 py-6">
                {navigation.map((item) => (
                  <a
                    key={item.name}
                    href={item.href}
                    className={`-mx-3 block rounded-lg px-3 py-2 text-base font-semibold leading-7 text-gray-900 
                      ${activeMenuItem === item.name ? 'bg-gray-300' : 'bg-white'} 
                      hover:bg-gray-50`}
                    onClick={() => handleMenuItemClick(item)}
                  >
                    {item.name}
                  </a>
                ))}
                <Disclosure as="div" className="-mx-3">
                  <DisclosureButton className="group flex w-full items-center justify-between rounded-lg py-2 pl-3 pr-3.5 text-base font-semibold leading-7 text-gray-900 hover:bg-gray-50">
                    채굴 패키지
                    <ChevronDownIcon aria-hidden="true" className="h-5 w-5 flex-none group-data-[open]:rotate-180" />
                  </DisclosureButton>
                  <DisclosurePanel className="mt-2 space-y-2">
                    {products.map((item) => (
                      <div className='flex flex-row items-center hover:bg-gray-50'>
                        <item.icon aria-hidden="true" className="ml-6 h-6 w-6 text-gray-600 group-hover:text-indigo-600" />
                        <DisclosureButton
                          key={item.name}
                          as="a"
                          href={item.href}
                          className="block rounded-lg py-2 pr-3 pl-3 text-sm font-semibold leading-7 text-gray-900"
                        >
                          {item.name}
                        </DisclosureButton>
                      </div>
                    ))}
                  </DisclosurePanel>
                </Disclosure>
              </div>
            </div>
          </div>
        </DialogPanel>
      </Dialog>
    </header>
  )
}
