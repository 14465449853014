import BasePage from "../../common/base"
import PageNotReady from "../../common/pageNotReady"


export default function BitcoinStakingPage() {
    return (
        <BasePage>
            {PageNotReady()}
        </BasePage>
    )
}