import { corporationData } from '../../data';
import logoImg from '../../asset/img/logo.jpg';
import { IdentificationIcon, ShieldCheckIcon, BuildingOfficeIcon } from '@heroicons/react/24/outline';

function HistoryRow({ col1, col2 }) {
  return (
    <div className="flex flex-row my-1 items-center">
      <div className='mr-2'><span className='date text-lg'>{col1}</span></div>
      <div>{col2}</div>
    </div>
  );
}

export default function CorpHistory() {
  return (
    <>
      <div className="relative isolate py-12">
        <div className="flex flex-row items-center justify-center mb-12">
          <BuildingOfficeIcon className="h-12 w-12 mr-3" />
          <span className="font-semibold opacity-80 text-4xl">
            회사 연혁
          </span>
        </div>
        <div className="flex justify-center">
          <div className='md:w-4/5 flex flex-col md:flex-row items-start justify-center'>
            <div className="mx-auto md:w-2/5 flex flex-col items-center mb-12 md:mb-0">
              <img className="h-72 w-auto" src={logoImg} alt="회사 로고" />
              <div className="py-3 text-xl text-center font-semibold">디블록</div>
            </div>
            <div className="mx-auto md:w-3/5">
              <div className="flex ps-0 lg:ps-16 xl:ps-24">
                <div className="flex flex-col">
                  <div className="mb-3">
                    <h3 className="text-2xl font-semibold">히스토리</h3>
                  </div>
                  {corporationData.map((element, index) => (
                    <HistoryRow key={index} {...element} />
                  ))}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
